<template>
  <full-view :title="getTitle" back-button>
    <template v-slot:toolbar>
      <v-btn large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text
        class="overflow-y-auto px-0 pt-3"
        :style="getViewHeightStyle(56)"
      >
        <v-form ref="form" v-model="valid" class="ma-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.name"
                :counter="200"
                :rules="rules.name"
                :label="$t('general.name')"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-card tile>
                <v-sheet
                  tile
                  height="40"
                  color="secondary"
                  class="pa-2 d-flex justify-space-between"
                >
                  <div class="v-label ma-1">
                    {{ $t("role.permissions") }}
                  </div>
                  <div>
                    <v-btn
                      small
                      depressed
                      color="primary"
                      class="px-1 mr-1"
                      @click="selectAll"
                    >
                      ALL
                    </v-btn>
                    <v-btn
                      small
                      depressed
                      color="primary"
                      class="px-1"
                      @click="discardAll"
                    >
                      NONE
                    </v-btn>
                  </div>
                </v-sheet>
                <v-list dense v-model="permissions">
                  <v-list-item-group color="primary">
                    <div v-for="(prm, i) in permissions" :key="i">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            getName(prm)
                          }}</v-list-item-title>
                          <v-list-item-subtitle v-text="prm.description" />
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            v-model="selectedPermissions"
                            :value="prm.id"
                          />
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <form-bar disable-validation @save="save" @reset="reset" />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import FullView from "@/common/layouts/FullView";
import FormBar from "@/common/components/FormBar";

export default {
  name: "RoleForm",
  components: { FullView, FormBar },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          name: "",
        };
      },
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPermissions: [],
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => v.indexOf(" ") === -1 || "No whitespaces",
        ],
      },
    };
  },
  watch: {
    item: {
      handler: function (n) {
        if (n) {
          this.selectedPermissions = n.permissions
            ? n.permissions.map((m) => m.id)
            : [];
          this.originalState = { ...n };
        }
      },
    },
  },
  computed: {
    getTitle() {
      return this.editMode ? this.$t("role.edit") : this.$t("role.new");
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },

    idParam() {
      return parseInt(this.$route.params.roleId);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    getName(perm) {
      return perm && perm.name ? perm.name.replace(".", " ") : "-";
    },

    save() {
      const item = { ...this.item, permissions: this.selectedPermissions };
      this.$emit("save", { item, editMode: this.editMode });
    },
    reset() {
      this.permissions = [];
      this.item = { ...this.originalState };
    },
    selectAll() {
      this.selectedPermissions = this.permissions.map((m) => m.id);
    },
    discardAll() {
      this.selectedPermissions = [];
    },
  },
};
</script>

<style scoped>
.text-deleted {
  text-decoration: line-through;
}
</style>
