<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(36)">
      <role-form :item="item" :permissions="getPermissions" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import RoleForm from "./RoleForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RoleEditor",
  components: {
    RoleForm,
  },
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    // if (this.editMode) {
    //   this.setLoadingState(this.$t("messages.loadingRole"));
    // } else {
    //   this.setLoadingState(this.$t("messages.initializingRole"));
    // }

    this.loadPermissions();

    if (this.editMode) {
      this.item = this.getRoleById(this.idParam);
      if (!this.item) {
        this.loadRoles().then(() => {
          this.item = this.getRoleById(this.idParam);
        });
      }
    }
  },
  computed: {
    ...mapGetters("roles", ["getPermissions","getRoleById"]),

    editMode() {
      return this.idParam > 0;
    },
    idParam() {
      return parseInt(this.$route.params.roleId);
    },
  },
  methods: {
    ...mapActions("roles", ["loadRoles", "saveRole", "loadPermissions"]),
    // ...mapActions("ui", [
    //   "setLoadingState",
    //   "clearLoadingState",
    // ]),

    save(params) {
      this.saveRole(params);
      this.$$router.back();
    },
  },
};
</script>
